import React, { useState } from 'react';
import styles from './LoginPage.module.css';

const LoginPage = ({ setIsLoggedIn }) => {
  const USERNAME = process.env.REACT_APP_USERNAME;
  const PASSWORD = process.env.REACT_APP_PASSWORD;
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');

  const handleLogin = (event) => {
    event.preventDefault()
    setError('');

    if (!username || !password) {
      setError('Please fill in all fields.');
      return;
    }

    const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
    if (!emailRegex.test(username)) {
      setError('Invalid email format.');
      return;
    }

    const passwordRegex = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z]).{8,}$/;
    if (!passwordRegex.test(password)) {
      setError(
        'Password must contain at least 8 characters, including uppercase, lowercase, and numbers.'
      );
      return;
    }

    if (username !== USERNAME || password !== PASSWORD) {
      setError('Invalid Credentials');
      return;
    }

    // set as logged in user and navigate to dashboard page
    sessionStorage.setItem('isLoggedIn', 'true');
    setIsLoggedIn(true);
  };

  return (
    <div className={styles.loginMainDiv}>
      <div className={styles.loginContainer}>
        <h1>Login</h1>
        {error && <p className={styles.error}>{error}</p>}
        <form>
          <div className={styles.inputGroup}>
            <label htmlFor="username">Username:</label>
            <input
              type="email"
              id="username"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
            />
          </div>
          <div className={styles.inputGroup}>
            <label htmlFor="password">Password:</label>
            <input
              type="password"
              id="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
          </div>
          <button type="submit" onClick={(event) => handleLogin(event)}>Login</button>
        </form>
      </div>
    </div>
  );
};

export default LoginPage;
