import React, { useState, useEffect } from 'react';
import styles from './FeedLists.module.css';
import Badge from '../UI/Badge';
import axios from 'axios';

const API_ENDPOINT = process.env.REACT_APP_ENDPOINT;

const FeedLists = ({ filter }) => {
    const [feedData, setFeedData] = useState([]);
    
    useEffect(() => {
        try {
            axios.get(API_ENDPOINT + '/process-logs/' + filter)
                .then((res) => {
                    if (res && res.data) {
                        setFeedData(res.data);
                    }
                })
                .catch((error) => {
                    // handle API call failed errors here
                    alert(error);
                });
        } catch (error) {
            // handle other errors here
            alert(error);
        }
    }, [filter]);

    return (
        <>
            <div className={styles.container}>
                <div>
                    <table className={styles.tableWrapper}>
                        <thead className={styles.headWrapper}>
                            <tr>
                                <th className={styles.rowWrapper}>Date / Time</th>
                                <th className={styles.rowWrapper}>Order ID</th>
                                <th className={styles.rowWrapper}>Status</th>
                                <th className={styles.rowUniqueWrapper}>Message</th>
                            </tr>
                        </thead>
                        <tbody>
                            {feedData.map((feed) =>
                                <tr key={feed._id}>
                                    <td className={styles.rowWrapper}>{feed.createdAt}</td>
                                    <td className={styles.rowWrapper}>{feed?.order_id}</td>
                                    <td className={styles.rowWrapper}><Badge statusCode={feed.log_level} /></td>
                                    <td className={styles.rowUniqueWrapper}>
                                        {feed.message}
                                        <p className={styles.extra_error}>{feed.extra_data}</p>
                                    </td>
                                </tr>
                            )}
                        </tbody>
                    </table>
                </div>
            </div>
        </>
    );
};

export default FeedLists;
