import React from 'react';

const Badge = ({ statusCode }) => {
    return (
        <>
            {statusCode == 3 ?
                <span className="badge badgeSuccess">Success</span>
                : statusCode == 4 ?
                    <span className="badge badgeInfo">Info</span>
                    :
                    statusCode == 6 ?
                        <span className="badge badgeDanger">Error</span>
                        :
                        statusCode == 5 ?
                            <span className="badge badgeWarning">Warning</span>
                            :
                            ''
            }
        </>
    );
};

export default Badge;