import React, { useState } from 'react';
import FeedLists from '../components/feeds/FeedLists';

const Dashboard = () => {
    const [filter, setFilter] = useState('display-logs');

    const handleFilterChange = (e) => {
        setFilter(e.target.value);
    };

    return (
        <div>
            <div className="ddWrapper">
                <select className="selectWrapper" onChange={handleFilterChange}>
                    <option value='display-logs'>Show all</option>
                    <option value='filter-success-logs'>Success</option>
                    <option value='filter-error-logs'>Errors</option>
                    <option value='filter-warn-logs'>Warnings</option>
                    <option value='filter-logs'>Info</option>
                </select>
            </div>
            <FeedLists filter={filter}/>
        </div>
    );
};

export default Dashboard;
