import React, { useState } from 'react';
import LoginPage from './pages/auth/LoginPage'
import Dashboard from './pages/MainPage';

const App = () => {
  const [isLoggedIn, setIsLoggedIn] = useState(
      sessionStorage.getItem('isLoggedIn') === 'true'
  );

  return isLoggedIn ? <Dashboard setIsLoggedIn={setIsLoggedIn} /> : <LoginPage setIsLoggedIn={setIsLoggedIn} />;
};

export default App;
